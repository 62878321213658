<template>
    <div class="plans_details_item">
        <p v-if="plan.tag" class="plans_details_item_tag">{{ plan.tag }}</p>

        <h3 class="plans_details_item_title">{{ plan.title }}</h3>
        <p v-if="plan.subTitle" class="plans_details_item_subTitle">{{ plan.subTitle }}</p>

        <div class="plans_details_item_price">
            <span class="plans_details_item_price_symbol">{{ plan.symbol }}</span>
            <span class="plans_details_item_price_amount" :class="{plans_details_item_price_amountSmallsize: parseInt(plan.price).toString().length >= 6}">
                        <strong>{{ amountSplit(plan.price) }}</strong><span class="plans_details_item_price_amount_cents"> /{{ plan.priceAnnual ? 'an' : 'mo' }}</span>
                    </span>

            <p v-if="plan.priceAnnual" class="plans_details_item_price_annual">{{ plan.symbol }}{{ amountSplit(plan.priceAnnual) }} por ano</p>
            <p class="plans_details_item_price_desc">{{ plan.description }}</p>
        </div>

        <div class="plans_details_item_desc">
            <p v-for="info in plan.info" class="plans_details_item_desc_item">
                <img class="plans_details_item_desc_item_img" :src="require('../../assets/imagem/verify.svg')" alt="">
                {{ info }}
            </p>

        </div>

        <a :href="plan.link" class="btn2 btn_blue plans_details_item_action">{{ translateWord('ASSINAR AGORA') }}</a>

        <div class="plans_details_item_details">
            <p class="plans_details_item_details_title">{{ translateWord('Ferramentas Inclusas no Plano:') }}</p>
            <p class="plans_details_item_details_desc">{{ plan.includedName }}</p>

            <img class="plans_details_item_details_image" :src="plan.imageTools" alt="Tools">
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "BoxPlan",
    props: {
        plan: Object
    },
    data(){
        return {}
    },
    computed: {
        ...mapGetters([
            'translateWord'
        ])
    },
    methods: {
        amountSplit(amount, isDecimal){
            let amountFormat = (amount / 100).toFixed(2);

            return isDecimal ? amountFormat.split('.')[1] : amountFormat.split('.')[0];

        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../scss/mixins';

    .plans_details_item{
        position: relative;
        margin-right: 20px;
        padding: 30px 15px;
        box-shadow: 0px 0px 10px rgba(37, 96, 223, 0.1);
        text-align: center;

    &:last-child{
         margin-right: 0px;
     }

    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3){
    & .plans_details_item_desc{
          margin-top: 35px;
      }
    }

    @include media(sm,md){
        width: auto;
        margin-bottom: 15px;
    }
    }
    .plans_details_item_tag{
        position: absolute;
        top: 35px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 0.65em;
        font-weight: 600;
        width: 120px;
        background: #f7f7f7;
        margin: auto;
        padding: 5px;
        border-radius: 5px;
        color: #2453b8fe;
    }
    .plans_details_item_title{
        font-size: 1.4em;
        font-weight: 600;
        margin-bottom: 10px;
        margin-top: 41px;
    }
    .plans_details_item_subTitle{
        font-size: 0.7em;
        font-weight: 400;
        margin-top: -15px;
    }

    .plans_details_item_price{
        font-weight: 600;
    }
    .plans_details_item_price_amount{
        color: #263B5E;

    & strong{
          font-size: 3.8em;
          font-weight: 600;
      }
    }
    .plans_details_item_price_amountSmallsize{
    & strong{
          font-size: 1.8em;
      }

    & + .plans_details_item_price_desc{
          margin-top: 0px;
      }
    }
    .plans_details_item_price_amount_cents{
        margin-left: -1px;
    }

    .plans_details_item_price_annual{
        color: #6a8695;
        font-size: 0.8em;
        margin-top: -12px;
    }
    .plans_details_item_price_desc{
        font-size: 0.7em;
        margin-top: -12px;
    }

    .plans_details_item_desc{
        font-size: 0.8em;
        margin: 17px auto;
        padding: 15px 0px;
        border-top: 1px solid var(--gray-500);
        border-bottom: 1px solid var(--gray-500);
    }

    .plans_details_item_desc_item_img{
        width: 12px;
    }

    .plans_details_item_action{
        display: inline-block;
        width: 100%;
    }

    .plans_details_item_details{
        font-size: 0.8em;
        margin-top: 25px;
    }
    .plans_details_item_details_title{
        color: #2453b8fe;
        font-weight: 600;
    }
    .plans_details_item_details_desc{
        font-size: 0.9em;
        font-weight: 400;
    }

    .plans_details_item_details_image{
        @include media(sm,md){
            width: 70%;
        }
    }

    .plans_details_item_price_amountSmallsize{
        & strong{
            font-size: 1.8em;
        }

        & + .plans_details_item_price_desc{
            margin-top: 0px;
        }
    }
</style>