<template>
    <div class="container plans_security">
        <div class="content">
            <div class="plans_security_item">
                <img class="plans_security_item_image" :src="require('../../assets/imagem/pay-card.png')" alt="">

                <div class="plans_security_item_info">
                    <p class="plans_security_item_info_text">{{ translateWord('ACEITAMOS TODAS AS BANDEIRAS') }}</p>

                    <img :src="require('../../assets/imagem/brands-union.png')" alt="" class="plans_security_item_info_image">
                </div>
            </div>

            <div class="plans_security_item">
                <img class="plans_security_item_image" :src="require('../../assets/imagem/ssl.png')" alt="">

                <div class="plans_security_item_info">
                    <p class="plans_security_item_info_text">{{ translateWord('SSL PAGAMENTO SEGURO') }}</p>
                    <p class="plans_security_item_info_textSub">{{ translateWord('Toda a informação é criptografada e transmitida sem risco usando um protocolo de camada de segurança. Você pode confiar em nós!.') }}</p>
                </div>
            </div>

            <div class="plans_security_item">
                <img class="plans_security_item_image" :src="require('../../assets/imagem/money.png')" alt="">

                <div class="plans_security_item_info">
                    <p class="plans_security_item_info_text">{{ translateWord('SATISFAÇÃO GARANTIDA') }}</p>
                    <p class="plans_security_item_info_textSub">{{ translateWord('Você pode cancelar quando desejar e dentro de 7 dias pode solicitar o reembolso do seu primeiro pagamento.') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "Security",
    data(){
        return {}
    },
    computed: {
        ...mapGetters([
            'translateWord'
        ])
    }
}
</script>

<style lang="scss" scoped>
    @import '../../scss/mixins';

    .plans_security {
        background: rgba(245, 249, 252, 0.3);

        & .content {
            display: flex;
            justify-content: space-between;

            @include media(sm, md) {
                flex-direction: column;
                align-items: center;
                text-align: center;
            }
        }
    }

    .plans_security_item {
        width: 33%;
        font-size: 0.7em;
        display: flex;
        align-items: flex-start;

        @include media(sm, md) {
            flex-direction: column;
            width: auto;
            margin-bottom: 15px;
        }
    }

    .plans_security_item_image {
        width: 55px;
        margin-right: 20px;

        @include media(sm, md) {
            margin: auto;
        }
    }

    .plans_security_item_info_image {
        width: 100px;
    }

    .plans_security_item_info_text {
        font-weight: 600;
    }

    .plans_security_item_info_textSub {
        font-size: 0.9em;
    }
</style>