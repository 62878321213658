<template>
    <div class="tools" v-if="loadTranslation">
        <div class="container tools_tool">
            <div class="content">
                <img class="tools_tool_img" :src="require('../assets/builderall-circle-blue.png')" title="Builderall">
                <h1 class="tools_tool_name">{{ title }}<span class="pointColor">.</span></h1>
                <p class="tools_tool_desc">{{ description }}</p>
            </div>
        </div>

        <div class="container tools_plans">
            <div class="content">
                <div class="tools_plans_left">
                    <img class="tools_plans_left_img" :src="require('../assets/builderall-black.png')" title="Builderall">
                    <h2 class="tools_plans_left_title">Escolha entre o plano Builderall Builder <span>ou</span> Premium com mais de 40 incríveis ferramentas.</h2>
                    <p class="tools_plans_left_desc">Escolha seu <b>plano {{periodSelect == 'MONTHLY' ? 'mensal' : 'anual'}}</b>, acesse seu escritório e <b>comece a construir seu negócio.</b></p>

                    <div class="tools_plans_left_actions">
                        <p class="tools_plans_left_actions_action"
                           @click="periodSelect = 'MONTHLY'"
                           :class="[periodSelect == 'MONTHLY' ? 'btn2 btn_blue active' : 'btn3']">Mensal</p>
                        <p class="tools_plans_left_actions_action"
                           @click="periodSelect = 'YEARLY'"
                           :class="[periodSelect == 'YEARLY' ? 'btn2 btn_blue active' : 'btn3']">Anual</p>
                    </div>
                </div>

                <div class="tools_plans_right">
                    <template v-if="periodSelect == 'MONTHLY'">
                        <BoxPlan
                            :plan="plans.month.url"
                        />
                        <BoxPlan
                            :plan="plans.month.premium"
                        />
                    </template>

                    <template v-if="periodSelect == 'YEARLY'">
                        <BoxPlan
                            :plan="plans.year.url"
                        />
                        <BoxPlan
                            :plan="plans.year.premium"
                        />
                    </template>
                </div>
            </div>
        </div>

        <template>
            <Security class="tools_security" />
        </template>

        <div class="container tools_resources">
            <div class="content">
                <Resources
                        name="Recursos"
                        :headers="resources.nameTools"
                        :resources="resources.items"
                />
            </div>
        </div>

        <template>
            <Questions
                :data="
                    [
                        {
                            text: this.translateWord('Existe a possibilidade de adicionar produtos ou fazer upgrades?'),
                            reply: this.translateWord('Sim! Nós temos opções adicionais e upgrades disponíveis exclusivos para potencializar seu negócio.'),
                            visible: false
                        }
                    ]
                "
            />
        </template>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Helper from '../assets/js/helper';
import BoxPlan from '../components/Pages/BoxPlan';
import Security from '../components/Pages/Security';
import Resources from '../components/Pages/Resources';
import Questions from '../components/Pages/Questions';

export default {
    name: "Tools",
    data(){
        return {
            loadTranslation: false,
            slug: '',
            title: '',
            description: '',
            firstPlan: null,
            secondPlan: null,
            resources: {
                nameTools: [],
                items: []
            },
            periodSelect: 'MONTHLY', //MONTHLY - YEARLY
            plans: {
                month: {
                    url: {
                        tag: false,
                        title: 'Builderall Builder',
                        subTitle: '',
                        symbol: '$',
                        price: '19990',
                        description: '',
                        info: ['1 Domínio', '3 Subdomínios', '2 GB Espaço em Disco', 'SSL Incluso'],
                        link: 'https://checkout.builderall.com/payment',
                        includedName: 'Builderall Builder',
                        imageTools: require('../assets/imagem/tools_1.png')
                    },
                    premium: {
                        tag: '🤩 BEST VALUE 🤩',
                        title: 'Builderall Premium',
                        subTitle: 'Plataforma de Marketing',
                        symbol: '$',
                        price: '19990',
                        description: '',
                        info: ['1 Domínio', '3 Subdomínios', '2 GB Espaço em Disco', 'SSL Incluso'],
                        link: 'https://checkout.builderall.com/payment',
                        includedName: 'Todas as Ferramentas Incluídas',
                        imageTools: require('../assets/imagem/tools_1.png')
                    }
                },
                year: {
                    url: {
                        tag: false,
                        title: 'Builderall Builder',
                        subTitle: '',
                        symbol: '$',
                        price: '2590',
                        priceAnnual: '120000',
                        description: '',
                        info: ['1 Domínio', '3 Subdomínios', '2 GB Espaço em Disco', 'SSL Incluso'],
                        link: 'https://checkout.builderall.com/payment',
                        includedName: 'Builderall Builder',
                        imageTools: require('../assets/imagem/tools_1.png')
                    },
                    premium: {
                        tag: '🤩 BEST VALUE 🤩',
                        title: 'Builderall Premium',
                        subTitle: 'Plataforma de Marketing',
                        symbol: '$',
                        price: '4590',
                        priceAnnual: '140000',
                        description: '',
                        info: ['1 Domínio', '3 Subdomínios', '2 GB Espaço em Disco', 'SSL Incluso'],
                        link: 'https://checkout.builderall.com/payment',
                        includedName: 'Todas as Ferramentas Incluídas',
                        imageTools: require('../assets/imagem/tools_1.png')
                    }
                }
            }
        }
    },
    computed: {
        ...mapState([
            'endpointOffice'
        ]),
        ...mapGetters([
            'getSlug',
            'getAllParamsUrl',
            'translateWord'
        ])
    },
    async created(){
        this.slug = `tool-${this.getSlug}`;

        await this.verifyTranslationPage('plans');

        [this.firstPlan, this.secondPlan] = (await Promise.all([
            this.$httpOffice.get(`${this.endpointOffice.PEGAR_PLANO_OU_TAXA}/${this.slug}?user_lang=${Helper.convertSigla(Helper.getLangBrowser())}`),
            this.$httpOffice.get(`${this.endpointOffice.PEGAR_PLANO_OU_TAXA}/premium-plan?user_lang=${Helper.convertSigla(Helper.getLangBrowser())}`)
        ])).map(plan => plan.data);

        if(this.firstPlan.status == 'error'){
            alert('PLANO NÃO ENCONTRADO. REDIRECIONAR PARA BUILDERALL.COM');
        }
        this.firstPlan = this.firstPlan.data;
        this.secondPlan = this.secondPlan.data;
        
        this.createResources();
        this.verifyAccessTool(this.firstPlan.ferramentas, 1);
        this.verifyAccessTool(this.secondPlan.ferramentas, 2);

        let countryPrices = await this.getCountryPrices(this.$route.query['country'] || null);

        let planChargeUrl = countryPrices.find(planCharge => planCharge.plan.slug == this.firstPlan.slug);
        let planChargePremium = countryPrices.find(planCharge => planCharge.plan.slug == this.secondPlan.slug);

        this.title = planChargeUrl.plan.title;
        this.description = this.firstPlan.descricao;

        this.plans.month.url.title = planChargeUrl.plan.title;
        this.plans.month.url.symbol = planChargeUrl.currency.symbol;
        this.plans.month.url.price = parseFloat(planChargeUrl.currency.value) * 100;
        this.plans.month.url.link += `/${this.slug}${this.getAllParamsUrl()}`;

        this.plans.month.premium.title = planChargePremium.plan.title;
        this.plans.month.premium.symbol = planChargePremium.currency.symbol;
        this.plans.month.premium.price = parseFloat(planChargePremium.currency.value) * 100;
        this.plans.month.premium.link += `/premium-plan${this.getAllParamsUrl()}`;

        this.plans.year.url.title = planChargeUrl.plan.title;
        this.plans.year.url.symbol = planChargeUrl.currency.symbol;
        this.plans.year.url.price = parseFloat(planChargeUrl.plan.value_month) * 100;
        this.plans.year.url.priceAnnual = parseFloat(planChargeUrl.plan.value_annual) * 100;
        this.plans.year.url.link = `/${this.slug}${this.getAllParamsUrl('period=y', true)}`;

        this.plans.year.premium.title = planChargePremium.plan.title;
        this.plans.year.premium.symbol = planChargePremium.currency.symbol;
        this.plans.year.premium.price = parseFloat(planChargePremium.plan.value_month) * 100;
        this.plans.year.premium.priceAnnual = parseFloat(planChargePremium.plan.value_annual) * 100;
        this.plans.year.premium.link = `/premium-plan${this.getAllParamsUrl('period=y', true)}`;

        this.loadTranslation = true;

    },
    methods: {
        ...mapActions([
            'verifyTranslationPage',
            'getCountryPrices'
        ]),
        createResources(){
            let tools = [].concat(this.firstPlan.ferramentas, this.secondPlan.ferramentas);

            this.resources.nameTools = [this.firstPlan.titulo, this.secondPlan.titulo];
            this.resources.items = tools.filter((name, index, arr) => arr.indexOf(name) === index).map(name => ({
                name: name,
                checks: [false, false]
            }));
        },
        verifyAccessTool(toolsCheck, indiceCheck){
            indiceCheck = --indiceCheck;

            toolsCheck.forEach(nameUrl => {
                this.resources.items.forEach((nameResource, index) => {
                    if(nameUrl == nameResource.name){
                        this.resources.items[index].checks[indiceCheck] = true
                    }
                })
            });
        }
    },
    components: {
        BoxPlan,
        Security,
        Resources,
        Questions
    }
}
</script>

<style lang="scss" scoped>
    .tools{
        font-family: Montserrat;

        & b{
            font-weight: 600;
        }
    }

    .tools_tool{
        background: #f5f9fc;
        text-align: center;
        padding-bottom: 45px;

        & .content{
            position: relative;
        }
    }

    .tools_tool_img{
        width: 70px;
        position: absolute;
        top: 31px;
        left: 2px;
    }
    .tools_tool_name{
        font-size: 3.8em;
        font-weight: 600;
        color: var(--black);

        & .pointColor{
            color: var(--blue);
        }
    }
    .tools_tool_desc{
        width: 480px;
        margin: auto;
    }

    .tools_plans{
        color: #212529FF;
        padding-top: 60px;

        & .content{
            display: flex;
            justify-content: space-between;
        }
    }

    .tools_plans_left{
        width: 518px;
        display: flex;
        flex-direction: column;
        margin-top: 38px;
    }
    .tools_plans_left_img{
        width: 100px;
        margin-bottom: 10px;
    }
    .tools_plans_left_title{
        font-size: 1.9em;
        font-weight: 600;
        color: #212529FF;
        line-height: 1.1;

        & span{
            color: #2661df;
        }
    }
    .tools_plans_left_desc{
        width: 385px;
        margin-top: 15px;
        font-weight: 400;
    }
    .tools_plans_left_actions{
        display: flex;
        align-items: center;
    }
    .tools_plans_left_actions_action{
        cursor: pointer;
        margin-top: 15px;
        margin-right: 10px;
        font-weight: 400;

        &.active{
            font-weight: 600;
        }

        &[class*='btn']{
            padding-left: 48px;
            padding-right: 48px;
        }
        &.btn3{
            border-width: 1px;
        }
    }

    .tools_plans_right{
        display: flex;
        max-width: 550px;
    }

    .tools_security{
        padding: 50px 0px;
    }

    .tools_resources{
        padding: 70px 0px;
        padding-bottom: 20px;
    }
</style>