<template>
    <div class="container questions">
        <div class="content">
            <div class="questions_container">
                <h1 class="questions_title">{{ translateWord('Perguntas frequentes') }}<span>.</span></h1>

                <div class="questions_questions">
                    <div v-for="question in questions" class="questions_questions_question">
                        <p @click="question.visible = !question.visible" class="questions_questions_question_text">{{ question.text }}</p>
                        <p v-if="question.visible" class="questions_questions_question_reply">{{ question.reply }}</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "Questions",
    props: {
        data: {
            type: Boolean
        }
    },
    data(){
        return {
            questions: []
        }
    },
    created(){
        this.questions = this.data;
    },
    computed: {
        ...mapGetters([
            'translateWord'
        ])
    }
}
</script>

<style lang="scss">
    @import "../../scss/mixins";

    .questions{
        & .content{
            padding: 60px 50px;

            @include media(sm,md){
                padding: 0px;
            }
        }
    }

    .questions_container{
        display: flex;
        justify-content: flex-start;

        @include media(sm,md){
            flex-direction: column;
        }
    }
    .questions_title{
        width: 300px;
        font-size: 2.8em;
        font-weight: 600;
        line-height: 1.2;
        color: var(--black);

        & span{
            color: rgb(1, 212, 185);
        }

        @include media(sm,md){
            font-size: 2em;
            width: auto;
            text-align: center;
        }
    }
    .questions_questions{
        width: 100%;
        margin-left: 40px;

        @include media(sm,md){
            margin-left: 0px;
        }
    }

    .questions_questions_question_text{
        font-weight: 600;
        color: var(--black);
        cursor: pointer;
        background: #fafcfd;
        padding: 20px;
    }
    .questions_questions_question_reply{
        padding: 20px;
    }
</style>